


















import Vue from "vue";
import { mapState } from "vuex";
import { Category, Location } from "@/types";
import CloseButton from "./CloseButton.vue";

export default Vue.extend({
  name: "ParkMapList",
  components: { CloseButton },
  computed: {
    ...mapState({
      categories: (state: any): Category[] => state.categories,
      parkLocations: (state: any): Location[] => state.parkLocations
    }),
    categoryTree(): Array<Category & { locations: Location[] }> {
      return this.categories.map(x => ({
        ...x,
        locations: this.parkLocations.filter(y =>
          y.categories.some(z => z.id === x.id)
        )
      }));
    }
  },
  methods: {
    selectLocation(id: number) {
      this.$store.commit("SELECTED_LOCATION_ID", id);
    }
  }
});






















import Vue from "vue";
import ParkMap from "./components/ParkMap.vue";
import ParkMapFilters from "./components/ParkMapFilters.vue";
import ParkMapList from "./components/ParkMapList.vue";
import { mapState } from "vuex";
import { Language } from "@/types";

export default Vue.extend({
  name: "app",
  components: {
    ParkMap,
    ParkMapFilters,
    ParkMapList
  },
  data() {
    return {
      toolBar: 0
    };
  },
  computed: {
    ...mapState({
      language: (state: any): Language => state.language
    }),
    toolBarSelectButtonFilterText() {
      const text = {
        [Language.SV]: "Filtrera",
        [Language.EN]: "Filter",
        [Language.DA]: "Filter",
        [Language.DE]: "Filter"
      };
      return text[this.language] ?? text[Language.SV];
    },
    toolBarSelectButtonListText() {
      const text = {
        [Language.SV]: "Lista",
        [Language.EN]: "List",
        [Language.DA]: "Liste",
        [Language.DE]: "Liste"
      };
      return text[this.language] ?? text[Language.SV];
    }
  },
  async beforeCreate() {
    await this.$store.dispatch("init");
  }
});

export interface Category {
  id: number;
  name: string;
  slug: string;
  icon: string;
}

export interface Theme {
  id: number;
  name: string;
  isDefault: boolean;
  mapImage: string;
}

export interface Position {
  lat: number;
  lng: number;
}

export interface Location {
  id: number;
  name: string;
  excerpt: string;
  image: {
    large: string;
    medium: string;
    square: string;
  };
  geo: Position;
  price: string;
  externalUrl: string;
  categories: Category[];
  themes: Theme[];
}

export const enum Language {
  SV = "sv",
  EN = "en",
  DA = "da",
  DE = "de"
}




























































import Vue from "vue";
import { mapState } from "vuex";
import { Category, Theme, Language } from "@/types";
import CloseButton from "./CloseButton.vue";

export default Vue.extend({
  name: "ParkMapFilters",
  components: { CloseButton },
  computed: {
    ...mapState({
      language: (state: any): Language => state.language,
      themes: (state: any): Theme[] => state.themes,
      categories: (state: any): Category[] => state.categories
    }),
    themeId: {
      get() {
        return this.$store.state.selectedThemeId;
      },
      set(id: number) {
        this.$store.commit("SELECTED_THEME_ID", id);
      }
    },
    categoryIds: {
      get() {
        return this.$store.state.selectedCategoryIds;
      },
      set(ids: number[]) {
        this.$store.commit("SELECTED_CATEGORY_IDS", ids);
      }
    },
    showAllButtonText() {
      const text = {
        [Language.SV]: "Visa alla",
        [Language.EN]: "Show all",
        [Language.DA]: "Vis alle",
        [Language.DE]: "Zeige alles"
      };
      return text[this.language] ?? text[Language.SV];
    },
    hideAllButtonText() {
      const text = {
        [Language.SV]: "Göm alla",
        [Language.EN]: "Hide all",
        [Language.DA]: "Skjul alle",
        [Language.DE]: "Verstecke alle"
      };
      return text[this.language] ?? text[Language.SV];
    }
  },
  methods: {
    showAllCategories() {
      this.categoryIds = this.categories.map(x => x.id);
    }
  }
});

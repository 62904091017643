var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-map',{ref:"parkMap",staticClass:"map",attrs:{"map-type-id":"terrain","options":_vm.options,"zoom":17,"center":{
    lat: 57.67429654019947,
    lng: 15.84564956560033
  }}},[(_vm.selectedLocation != null)?_c('gmap-info-window',{key:("info-" + (_vm.selectedLocation.id)),attrs:{"position":_vm.selectedLocation.geo,"opened":_vm.selectedLocation != null,"options":{
      pixelOffset: {
        width: 0,
        height: -30
      }
    }},on:{"closeclick":_vm.onCloseInfoWindow}},[_c('div',{staticClass:"map-popup"},[_c('img',{attrs:{"src":_vm.selectedLocation.image.medium}}),_c('div',{staticClass:"map-popup-content"},[_c('span',{staticClass:"popup-header"},[_vm._v(_vm._s(_vm.selectedLocation.name))]),(_vm.selectedLocation.externalUrl !== '')?_c('a',{staticClass:"link-btn",attrs:{"href":_vm.selectedLocation.externalUrl}},[_vm._v(_vm._s(_vm.readMoreButtonText))]):_vm._e()]),_c('div',{staticClass:"map-popup-content"},[_c('div',{staticClass:"excerpt"},[_vm._v(_vm._s(_vm.selectedLocation.excerpt))])]),(_vm.selectedLocation.price !== '')?_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.selectedLocation.price))]):_vm._e()])]):_vm._e(),_c('ground-overlay',{key:_vm.selectedThemeOverlayImgSrc,attrs:{"source":_vm.selectedThemeOverlayImgSrc,"bounds":{
      north: 57.6765472222686,
      south: 57.67104585813033,
      west: 15.84091833862305,
      east: 15.851440792577613
    },"opacity":1}}),_vm._l((_vm.markers),function(marker){return _c('gmap-marker',{key:("marker-" + (marker.id)),staticClass:"map-marker",attrs:{"position":marker.geo,"clickable":true,"icon":{
      url: marker.categories[0].icon,
      size: { width: 60, height: 60, f: 'px', b: 'px' },
      scaledSize: { width: 60, height: 60, f: 'px', b: 'px' }
    },"animation":2},on:{"click":function($event){return _vm.onMarkerClicked(marker.id)}}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
declare var wpml_lang: string;
import Vue from "vue";
import Vuex from "vuex";
import { Theme, Category, Location } from "@/types";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    parkLocations: [] as Location[],
    themes: [] as Theme[],
    categories: [] as Category[],
    selectedThemeId: null as number | null,
    selectedLocationId: null as number | null,
    selectedCategoryIds: [] as number[],
    language: wpml_lang
  },
  mutations: {
    PARK_LOCATIONS(state, payload) {
      state.parkLocations = Object.freeze(payload);
    },
    THEMES(state, payload) {
      state.themes = Object.freeze(payload);
    },
    CATEGORIES(state, payload) {
      state.categories = Object.freeze(payload);
    },
    SELECTED_THEME_ID(state, payload) {
      state.selectedThemeId = payload;
    },
    SELECTED_CATEGORY_IDS(state, payload) {
      state.selectedCategoryIds = payload;
    },
    SELECTED_LOCATION_ID(state, payload) {
      state.selectedLocationId = payload;
    }
  },
  actions: {
    async init({ commit, state }) {
      const response = await fetch(
        process.env.NODE_ENV === "production"
          ? `/wp-json/parkmap/v1/map?wpml_language=${state.language}`
          : `https://alvparkkarta.mindbite.cloud/wp-json/parkmap/v1/map?wpml_language=${state.language}`
      );
      const {
        parklocations,
        themes,
        categories
      }: {
        parklocations: Location[];
        themes: Theme[];
        categories: Category[];
      } = await response.json();
      commit("PARK_LOCATIONS", parklocations);
      commit("THEMES", themes);
      commit("CATEGORIES", categories);

      commit("SELECTED_THEME_ID", themes.find(x => x.isDefault)?.id);
      commit(
        "SELECTED_CATEGORY_IDS",
        categories.map(x => x.id)
      );
    }
  },
  getters: {
    selectedTheme(state) {
      return state.themes.find(x => x.id === state.selectedThemeId) ?? null;
    },
    selectedLocation(state) {
      return (
        state.parkLocations.find(x => x.id === state.selectedLocationId) ?? null
      );
    },
    filteredLocations(state) {
      return state.parkLocations.filter(
        x =>
          x.themes.some(y => y.id === state.selectedThemeId) &&
          x.categories.some(y => state.selectedCategoryIds.includes(y.id))
      );
    }
  }
});

declare var gmap_key: string;
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
//@ts-ignore
import * as VueGoogleMaps from "vue2-google-maps";
import "whatwg-fetch";

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: gmap_key
  },
  installComponents: true
});

new Vue({
  store,
  render: h => h(App)
}).$mount("#app");

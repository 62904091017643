







































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import styles from "../assets/gmap-styles.json";
import { Theme, Category, Location, Position, Language } from "@/types";
//@ts-ignore
import { MapElementFactory } from "vue2-google-maps";

Vue.component(
  "ground-overlay",
  MapElementFactory({
    mappedProps: {
      opacity: {}
    },
    props: {
      source: { type: String },
      bounds: { type: Object }
    },
    events: ["click", "dblclick"],
    name: "groundOverlay",
    //@ts-ignore
    ctr: () => google.maps.GroundOverlay,
    //@ts-ignore
    ctrArgs: (options, { source, bounds }) => [source, bounds, options]
  })
);

export default Vue.extend({
  name: "ParkMap",
  created(this: any) {
    this.options = {
      disableDefaultUI: true,
      styles
    };
  },
  computed: {
    ...mapState({
      language: (state: any): Language => state.language
    }),
    selectedTheme(): Theme | null {
      return this.$store.getters.selectedTheme;
    },
    selectedLocation(): Location | null {
      return this.$store.getters.selectedLocation;
    },
    markers(): Location[] {
      return this.$store.getters.filteredLocations;
    },
    selectedThemeOverlayImgSrc(): string {
      return this.selectedTheme?.mapImage ?? "";
    },
    readMoreButtonText() {
      const text = {
        [Language.SV]: "Läs mer",
        [Language.EN]: "Read more",
        [Language.DA]: "Læs mere",
        [Language.DE]: "Weiterlesen"
      };
      return text[this.language] ?? text[Language.SV];
    }
  },
  methods: {
    panTo(this: any, position: Position, offset: number = 0) {
      this.$refs.parkMap?.panTo(position);
      this.$refs.parkMap?.panBy(0, offset);
    },
    onMarkerClicked(id: number) {
      this.$store.commit(
        "SELECTED_LOCATION_ID",
        id !== this.selectedLocation?.id ? id : null
      );
    },
    onCloseInfoWindow() {
      this.$store.commit("SELECTED_LOCATION_ID", null);
    }
  },
  watch: {
    markers(filteredLocations: Location[]) {
      if (!filteredLocations.some(x => x.id === this.selectedLocation?.id))
        this.onCloseInfoWindow();
    },
    selectedLocation(location: Location | null) {
      if (location) {
        this.panTo(location.geo, -150);
        this.$emit("marker-selected");
      }
    }
  }
});
